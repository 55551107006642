import Gallery from "../../components/Gallery/Gallery";
import Spinner from "../../components/Spinner/Spinner";
import Meta from "../../components/meta/Meta";
import EventsNews from "./EventsNews/EventsNews";
import HeroNews from "./HeroNews/HeroNews";
import "./News.css";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
function News({
  setLanguage,
  language,
  loadingEvents,
  events,
  galleryContent,
  logo,
  siteName,
  loadingLogo,
  newsTitle,
  newsDesc,
  newsCanonical,
  newsSlug,
  newsRedirect,
}) {
  const navigate = useNavigate();
  console.log("news slug", newsSlug);
  useEffect(() => {
    if (newsRedirect) {
      window.location.href = newsRedirect;
    }
  }, [newsRedirect]);
  useEffect(() => {
    const currentSlug = window.location.pathname.split("/").pop();

    if (newsSlug) {
      if (currentSlug !== newsSlug) {
        navigate(`/news/${newsSlug}`);
      }
    }
  }, [navigate, newsSlug]);
  return (
    <>
      {loadingEvents || loadingLogo ? (
        <Spinner />
      ) : (
        <div>
          <Meta title={newsTitle} desc={newsDesc} canonical={newsCanonical} />
          <HeroNews
            setLanguage={setLanguage}
            language={language}
            events={events}
            loadingEvents={loadingEvents}
            logo={logo}
            siteName={siteName}
            loadingLogo={loadingLogo}
          />
          <EventsNews
            language={language}
            events={events}
            loadingEvents={loadingEvents}
          />
          <Gallery language={language} galleryContent={galleryContent} />
        </div>
      )}
    </>
  );
}

export default News;
