import React, { useState } from 'react';
import './GalleryCard.css';
const url_main = 'https://el-shrouk-hospital-dashboard.technomasrsystems.com';
function GalleryCard({ item }) {
	const [isHover, setIsHover] = useState(false);
	return (
		<div onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)} className={`galleryCard text-center transition duration-500`}>
			{isHover && <h3 className='GalleryCard__title mb-3'>{item.categoryName}</h3>}

			<img src={`${item.images}`} alt={item.ImgAlt} loading='lazy' />
		</div>
	);
}

export default GalleryCard;
