import React from "react";
import "./MedicalCard.css";
import { BsArrowDownShort } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
const url_main = "https://el-shrouk-hospital-dashboard.technomasrsystems.com";
function MedicalCard({ item, language }) {
  console.log("medical card", item);
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate(`/equipment/${item.id}`);
  };
  return (
    <div className={`medicalCard ${language === "ar" && "medicalCard__ar"}`}>
      <div className="medicalCard__img">
        <img src={item.image} alt={item.ImgAlt} />
      </div>
      <div className="medicalCard__content">
        <h2>{item?.title}</h2>

        <div
          className="mx-3"
          dangerouslySetInnerHTML={{
            __html: item.description.slice(0, 200),
          }}
        />
        <button onClick={handleNavigate} className="medicalCardToRead__read">
          {language === "en" ? "Read More" : "اقرأ المزيد"}
          <BsArrowDownShort />
        </button>
      </div>
      <div className="medicalCard__icon">
        <img src={item.icon} alt={item?.titlte} />
      </div>
    </div>
  );
}

export default MedicalCard;
