import React, { useState } from "react";
import "./MedicalCardToRead.css";
import { BsArrowUpShort, BsArrowDownShort } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
const url_main = "https://el-shrouk-hospital-dashboard.technomasrsystems.com";
const ReadMore = ({ children, language, item }) => {
  console.log("this is the item from read more btn", item);
  const text = children;

  const navigate = useNavigate();
  const [isReadMore, setIsReadMore] = useState(true);
  const handleNavigate = () => {
    navigate(`/services/${item.id}/${item.searchSlug}`);
  };
  return (
    <>
      <p className="text">{isReadMore ? text.slice(0, 200) : text}</p>

      <button onClick={handleNavigate} className="medicalCardToRead__read">
        {language === "en" ? "Read More" : "اقرأ المزيد"}
        <BsArrowDownShort />
      </button>
    </>
  );
};

function MedicalCardToRead({ item, language }) {
  return (
    <div className="medicalCardToRead  ">
      <div className="medicalCardToRead__title">
        <img src={item.icon} alt={item.title} />
        <h3>{item.title}</h3>
      </div>
      <div className="medicalCardToRead__description">
        <ReadMore item={item} language={language}>
          {item.description}
        </ReadMore>
      </div>
    </div>
  );
}

export default MedicalCardToRead;
