import { useEffect } from "react";
import Hero from "../../components/Hero/Hero";
import News from "../../components/News/News";
import ServecisSection from "../../components/ServecisSection/ServecisSection";
import WeCare from "../../components/WeCare/WeCare";
import Team from "../../components/Team/Team";
import arrow from "../../assets/arrow.svg";
import Meta from "../../components/meta/Meta";
import Spinner from "../../components/Spinner/Spinner";
import { useNavigate } from "react-router-dom";
function Home({
  language,
  setLanguage,
  mainPageHero,
  loadingSettings,
  loadingServices,
  servicesData,
  loadingEvents,
  events,
  logo,
  siteName,
  loadingLogo,
  loading,
  title,
  desc,
  redirect,
  slug,
  canonical,
}) {
  const navigate = useNavigate();
  useEffect(() => {
    if (
      loadingEvents &&
      loadingServices &&
      loadingSettings &&
      loading &&
      redirect
    ) {
      window.location.href = redirect;
    }
  }, [loadingEvents, loadingServices, loadingSettings, loading, redirect]);

  useEffect(() => {
    const currentSlug = window.location.pathname.split("/").pop();

    if (
      !loadingServices &&
      !loadingSettings &&
      !loadingEvents &&
      !loading &&
      slug
    ) {
      if (currentSlug !== slug) {
        navigate(`/${slug}`);
      }
    }
  }, [
    slug,
    loadingEvents,
    loadingServices,
    loadingSettings,
    loading,
    navigate,
  ]);
  return (
    <div>
      {loadingEvents ||
      loadingLogo ||
      loadingServices ||
      loadingSettings ||
      loading ? (
        <Spinner />
      ) : (
        <div>
          <Meta title={title} desc={desc} canonical={canonical} />
          <Hero
            setLanguage={setLanguage}
            language={language}
            mainPageHero={mainPageHero}
            loadingSettings={loadingSettings}
            logo={logo}
            siteName={siteName}
            loadingLogo={loadingLogo}
          />
          <div className="container flex justify-center items-center mt-10  lg:mt-16">
            <a href="#services">
              <img src={arrow} alt="icon" loading="lazy" />
            </a>
          </div>
          <ServecisSection
            language={language}
            servicesData={servicesData}
            loadingServices={loadingServices}
            loadingLogo={loadingLogo}
            loadingSettings={loadingSettings}
          />
          <WeCare language={language} />
          <News
            language={language}
            loadingEvents={loadingEvents}
            events={events}
          />
          <Team language={language} />
        </div>
      )}
    </div>
  );
}

export default Home;
