import React, { useState, useEffect } from "react";
import style from "./equipment.module.css";
import Navbar from "../../components/Navbar/Navbar";
import { useParams } from "react-router-dom";
import Spinner from "../../components/Spinner/Spinner";
import Meta from "../../components/meta/Meta";
import { useNavigate } from "react-router-dom";
const Equipment = ({ language, setLanguage, logo, siteName, loadingLogo }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState({});
  const url_main = "https://admin.elshoroukhospital.com";
  const [loading, setLoading] = useState(true);
  const [equipmentTitle, setEquipmentTitle] = useState("");
  const [equipmentDesc, setEquipmentDesc] = useState("");
  const [equipmentCanonical, setEquipmentCanonical] = useState("");
  const [equipmentSlug, setEquipmentSlug] = useState("");
  const [equipmentRedirect, setEquipmentRedirect] = useState("");
  // get data from api
  useEffect(() => {
    fetch(`${url_main}/api/equipment/${parseInt(id)}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        lang: language,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setData(data);
        setEquipmentCanonical(data.searchCanonical);
        setEquipmentDesc(data.searchMetaDes);
        setEquipmentTitle(data.searchTitle);
        setEquipmentSlug(data.searchSlug);
        setEquipmentRedirect(data.searchCustomUrl);
        setLoading(false);
      });
  }, [language, id]);
  //  handle slug
  useEffect(() => {
    const currentSlug = window.location.pathname.split("/").pop();
    console.log("this is the current slug", currentSlug);
    if (!loading && equipmentSlug) {
      if (currentSlug !== equipmentSlug) {
        navigate(`/services/${id}/${equipmentSlug}`);
      }
    }
  }, [loading, navigate, equipmentSlug, id]);

  // handle redirect
  useEffect(() => {
    if (!loading && equipmentRedirect) {
      window.location.href = equipmentRedirect;
    }
  }, [loading, equipmentRedirect]);
  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <Meta
            title={equipmentTitle}
            desc={equipmentDesc}
            canonical={equipmentCanonical}
          />
          <div className={style.mainContainer}>
            <div className="container mb-5">
              <Navbar
                setLanguage={setLanguage}
                language={language}
                logo={logo}
                siteName={siteName}
                loadingLogo={loadingLogo}
              />
              <div className="mt-5 pt-4 row justify-content-center">
                <div className="col-12 col-md-10">
                  <h3 className={`m-0 p-0 mb-3 text-center ${style.title}`}>
                    {data.title}
                  </h3>

                  <img
                    loading="lazy"
                    alt={data.ImgAlt}
                    className={style.mainImg}
                    src={data.image}
                  />
                  <div
                    className="mt-3"
                    dangerouslySetInnerHTML={{ __html: data.description }}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Equipment;
