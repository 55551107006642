import React from "react";
import Spinner from "../Spinner/Spinner";
import "./ServecisSection.css";
import ServiceCard from "./ServiceCard/ServiceCard";
import right from "../../assets/Layer 1.svg";
import Left from "../../assets/Layer 2.svg";
function ServecisSection({
  language,
  servicesData,
  loadingServices,
  loadingEvents,
  loadingLogo,
  loadingSettings,
  loading,
}) {
  return (
    <>
      {loadingServices || loadingLogo || loadingSettings ? (
        <Spinner />
      ) : (
        <div className="servecisSection" id="services">
          <div className="container">
            <div className="flex-center">
              <div className="flex justify-center items-center mb-12">
                <div className="flex items-center gap-6">
                  <div className="iconContainer">
                    <img src={right} alt="" className="img" />
                  </div>

                  <h2 className="title">
                    {servicesData.StaticServicesData.ServiceTitle}
                  </h2>
                  <div className="iconContainer">
                    <img src={Left} alt="" className="img" />
                  </div>
                </div>
              </div>
            </div>
            <p className="servecisSection__paragraph">
              {servicesData.StaticServicesData.ServicesDes}
            </p>
            <div className="servecisSection__cards">
              {servicesData?.Services?.map((item, index) => (
                <ServiceCard key={index} item={item} language={language} />
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ServecisSection;
