import "./ContactUs.css";
// import facebookIcon from "../../assets/facebook.png";
import linkedinIcon from "../../assets/icons8-linkedin.svg";
import instaIcon from "../../assets/icons8-instagram.svg";
import youtubeIcon from "../../assets/icons8-youtube.svg";
import facebook from "../../assets/icons8-facebook.svg";
import Spinner from "../Spinner/Spinner";
import right from "../../assets/Layer 1.svg";
import Left from "../../assets/Layer 2.svg";
import { useLocation } from "react-router-dom";

function ContactUs({ contact_data, language, loadingSettings, social }) {
  const location = useLocation();
  const is404 =
    location.pathname !== "/" &&
    !location.pathname.startsWith("/about") &&
    !location.pathname.startsWith("/services") &&
    !location.pathname.startsWith("/news") &&
    !location.pathname.startsWith("/doctors") &&
    !location.pathname.startsWith("/about");
  return (
    <>
      {!is404 && (
        <section id="contact" className="contactUs mb-20">
          <div className="container">
            <div className="flex justify-center items-center mb-12">
              <div className="flex items-center gap-6">
                <div className="iconContainer">
                  <img src={right} alt="" className="img" />
                </div>

                <h2 className="title">
                  {language === "en" ? "Contact Us" : "تواصل معنا"}
                </h2>
                <div className="iconContainer">
                  <img src={Left} alt="" className="img" />
                </div>
              </div>
            </div>

            {loadingSettings ? (
              <Spinner />
            ) : (
              <div className="contactUs__image flex">
                <div className="contactUs__image__content rounded-3xl bg-white p-6 ">
                  <h3>{language === "en" ? "Contact" : "التواصل"}</h3>
                  <h4 className="flex items-center gap-1 contactUs__phone__div">
                    {language === "en" ? "Hotline" : "الخط الساخن"} :
                    <span className="contactUs__phone">
                      <a
                        href={`tel:${contact_data.hotLine}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {contact_data.hotLine}
                      </a>
                    </span>
                  </h4>
                  <h4 className="flex items-center gap-1">
                    {language === "en" ? "Email" : "البريد الالكترونى"} :
                    <span>{contact_data.email}</span>
                  </h4>
                  <h4 className="flex items-center gap-1">
                    {language === "en" ? "WhatsApp" : "واتساب"} :
                    <span>
                      <a
                        href={`https://wa.me/${contact_data.whatsapp}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {contact_data.whatsapp}
                      </a>
                    </span>
                  </h4>
                  <h3>{language === "en" ? "Location" : "العنوان"}</h3>
                  <p>{contact_data.address}</p>
                  <h3 className="mt-2">
                    {language === "en" ? "Social Media" : " التواصل الاجتماعى"}
                  </h3>
                  <div className="flex space-x-1 icons__contact">
                    <a
                      href={social.facebook}
                      target="_blank"
                      rel="noreferrer"
                      className="svgContainer"
                    >
                      <img src={facebook} alt="linkedinIcon" />
                    </a>
                    <a
                      href={social.linkedin}
                      target="_blank"
                      rel="noreferrer"
                      className="svgContainer"
                    >
                      <img src={linkedinIcon} alt="linkedinIcon" />
                    </a>
                    <a
                      href={social.youtube}
                      target="_blank"
                      rel="noreferrer"
                      className="svgContainer"
                    >
                      <img src={youtubeIcon} alt="youtubeIcon" />
                    </a>
                    <a
                      href={social.instagram}
                      target="_blank"
                      rel="noreferrer"
                      className="svgContainer"
                    >
                      <img src={instaIcon} alt="instaIcon" />
                    </a>
                  </div>
                </div>

                <div className="contactUs__mapGoogle  py-3">
                  <iframe
                    src={contact_data?.map}
                    width="100%"
                    height="100%"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                    title="el-shrouk-hospital"
                  ></iframe>
                </div>
              </div>
            )}
          </div>
        </section>
      )}
    </>
  );
}

export default ContactUs;
