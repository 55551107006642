import React, { useEffect, useState } from "react";
import MedicalCard from "./MedicalCard/MedicalCard";
import MedicalCardToRead from "./MedicalCardToRead/MedicalCardToRead";
import "./MedicalServices.css";
import elipse from "../../assets/Ellipse 116.svg";
import Spinner from "../../components/Spinner/Spinner";
import HeroMedical from "./HeroMedical/HeroMedical";
import Meta from "../../components/meta/Meta";
import { useNavigate } from "react-router-dom";
const url_main = "https://admin.elshoroukhospital.com";
function MedicalServices({
  setLanguage,
  language,
  servicesData,
  logo,
  siteName,
  loadingLogo,
}) {
  const [toggle, setToggle] = useState(1);
  const navigate = useNavigate();
  const [equipments, setEquipments] = useState([]);
  const [loadingEquipments, setLoadingEquipments] = useState(true);
  const toggleTap = (index) => {
    setToggle(index);
  };

  const [servicesTitle, setServicesTitle] = useState("");
  const [servicesDesc, setServicesDesc] = useState("");
  const [servicesCanonical, setServicesCanonical] = useState("");
  const [servicesRedirect, setServicesRedirect] = useState("");
  const [servicesSlug, setServicesSlug] = useState("");
  useEffect(() => {
    fetch(`${url_main}/api/equipments`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        lang: language,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setServicesTitle(data.searchData.Title);
        setServicesDesc(data.searchData.Meta);
        setServicesCanonical(data.searchData.Canonical);
        setServicesRedirect(data.searchData.CustomUrl);
        setServicesSlug(data.searchData.Slug);
        setEquipments(data);
        setLoadingEquipments(false);
      });
  }, [language]);

  // Redirect after the data has been loaded and aboutRedirect is not empty
  useEffect(() => {
    if (!loadingEquipments && servicesRedirect) {
      window.location.href = servicesRedirect;
    }
  }, [servicesRedirect, loadingEquipments]);

  // handle slug
  useEffect(() => {
    const currentSlug = window.location.pathname.split("/").pop();
    console.log("this is the current slug", currentSlug);
    if (!loadingEquipments && servicesSlug) {
      if (currentSlug !== servicesSlug) {
        navigate(`/services/${servicesSlug}`);
      }
    }
  }, [loadingEquipments, navigate, servicesSlug]);
  return (
    <>
      <div className="medicalServices">
        {loadingEquipments ? (
          <Spinner />
        ) : (
          <>
            <HeroMedical
              setLanguage={setLanguage}
              language={language}
              servicesData={servicesData}
              toggleTap={toggleTap}
              toggle={toggle}
              logo={logo}
              siteName={siteName}
              loadingLogo={loadingLogo}
            />
            <div className="medicalServices__container mt-10 container px-5 lg:px-0">
              <div className="elipseContainer">
                <img src={elipse} alt="" className="elipseServ" />
              </div>
              <img src={elipse} alt="" className="elipseTwoServ" />
              <div className="medicalServices__allCards">
                <div
                  className={
                    toggle === 1
                      ? "medicalServices__content active"
                      : "medicalServices__content"
                  }
                >
                  {servicesData?.Services?.map((item, index) => (
                    <MedicalCardToRead
                      key={index}
                      item={item}
                      language={language}
                    />
                  ))}
                </div>
                <div
                  className={
                    toggle === 2
                      ? "medicalServices__content active"
                      : "medicalServices__content"
                  }
                >
                  <div className="medicalServices__equipment my-32">
                    {equipments?.equipments?.map((item, index) => (
                      <MedicalCard
                        key={index}
                        item={item}
                        language={language}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default MedicalServices;
