import { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import ScrollToTop from "react-scroll-to-top";
import Footer from "./components/Footer/Footer";
import About from "./pages/about/About";
import Home from "./pages/Home/Home";
import MedicalServices from "./pages/MedicalServices/MedicalServices";
import News from "./pages/News/News";
import NewsInside from "./pages/NewsInside/NewsInside";
import ContactUs from "./components/ContactUs/ContactUs";
// import ContactUsPage from "./pages/ContactUsPage/ContactUsPage";
import MeetOurTeam from "./pages/MeetOurTeam/MeetOurTeam";
import AppWrap from "./wrapper/AppWrap";
import OurDoctors from "./pages/ourDoctors/OurDoctors";
import DoctorDetails from "./pages/DoctorDetails/DoctorDetails";
import Spinner from "./components/Spinner/Spinner";
import NotFound from "./pages/Error/NotFound";
import Service from "./pages/serv/Service";
import Equipment from "./pages/equipments/Equipment";
const url_main = "https://admin.elshoroukhospital.com";

function App() {
  const [loadingSettings, setLoadingSettings] = useState(true);
  const [loadingServices, setLoadingServices] = useState(true);
  const [servicesData, setServicesData] = useState([]);
  const [mainPageHero, setMainPageHero] = useState({});
  const [contact_data, setContact_data] = useState({});
  const [siteName, setSiteName] = useState({});
  const [logo, setLogo] = useState("");
  const [social, setSocial] = useState({});
  const [loadingLogo, setLoadingLogo] = useState(true);
  const [language, setLanguage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [newsSlug, setNewsSlug] = useState("");
  // doctors
  const [doctorTitle, setDoctorTitle] = useState("");
  const [doctorDesc, setDoctorDesc] = useState("");
  const [doctorSlug, setDoctorSlug] = useState("");
  const [doctorRedirect, setDoctorRedirect] = useState("");
  const [doctorCanonical, setDoctorCanonical] = useState("");
  function ScrollToTopAfterChangePage() {
    const { pathname } = useLocation();
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
    return null;
  }

  useEffect(() => {
    setLanguage(
      localStorage.getItem("shroukLanguage")
        ? JSON.parse(localStorage.getItem("shroukLanguage"))
        : localStorage.setItem("shroukLanguage", JSON.stringify("en"))
    );

    // localStorage.setItem("shroukLanguage", language ? userLanguage : '')
  }, []);

  // To Change Direction In Body html and css
  useEffect(() => {
    document.documentElement.setAttribute("lang", language);
    if (language === "ar") {
      document.getElementsByTagName("body")[0].style.direction = "rtl";
      document.getElementsByTagName("body")[0].style.fontFamily =
        "'Noto Kufi Arabic', sans-serif";
    } else {
      document.getElementsByTagName("body")[0].style.direction = "ltr";
      document.getElementsByTagName("body")[0].style.fontFamily =
        "'Montserrat', sans-serif";
    }
  }, [language]);
  // settings for main page
  const [websiteTitle, setWebsiteTitle] = useState(
    language === "en" ? "El-Shorouk Hospital" : "مستشفي الشروق "
  );
  const [websiteDesc, setWebsiteDesc] = useState("");
  const [homeTitle, setHomeTitle] = useState("");
  const [homeDesc, setHomeDesc] = useState("");
  const [homeRedirect, setHomeRedirect] = useState("");
  const [homeSlug, setHomeSlug] = useState("");
  const [homeCanonical, setHomeCanonical] = useState("");
  useEffect(() => {
    fetch(`${url_main}/api/settings`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        lang: language,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setMainPageHero(data?.data[0]?.mainPage);
        setWebsiteTitle(
          language === "en"
            ? data.data[0].general_Settings.siteTitle_en
            : data.data[0].general_Settings.siteTitle_ar
        );
        setWebsiteDesc(data.data[0].general_Settings.siteDescription);
        setContact_data(data?.data[0]?.contact_data);
        setSocial(data?.data[0]?.social);
        setSiteName(data?.data[0]?.general_Settings);
        setLogo(data?.data[0]?.siteLogo);
        setDoctorTitle(data.data[0].searchDataDoctors.title);
        setDoctorDesc(data.data[0].searchDataDoctors.Meta);
        setDoctorRedirect(data.data[0].searchDataDoctors.CustomUrl);
        setDoctorSlug(data.data[0].searchDataDoctors.Slug);
        setDoctorCanonical(data.data[0].searchDataDoctors.Canonical);
        setHomeTitle(data.data[0].searchMainData.title);
        setHomeDesc(data.data[0].searchMainData.Meta);
        setHomeCanonical(data.data[0].searchMainData.Canonical);
        setHomeRedirect(data.data[0].searchMainData.CustomUrl);
        setHomeSlug(data.data[0].searchMainData.Slug);
        setLoadingSettings(false);
        setLoadingLogo(false);
      });
  }, [language]);

  useEffect(() => {
    fetch(`${url_main}/api/services`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        lang: language,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setServicesData(data);
        setLoadingServices(false);
      });
  }, [language]);

  // To Fetch News
  const [loadingEvents, setLoadingEvents] = useState(true);
  const [events, setEvents] = useState([]);
  const [galleryContent, setGalleryContent] = useState({});
  const [newsTitle, setNewsTitle] = useState("");
  const [newsDesc, setNewsDesc] = useState("");
  const [newsCanonical, setNewsCanonical] = useState("");
  const [newsRedirect, setNewsRedirect] = useState("");
  useEffect(() => {
    fetch(`${url_main}/api/newsEvents`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        lang: language,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setEvents(data);

        setNewsTitle(data.searchData.Title);
        setNewsDesc(data.searchData.Meta);
        setNewsCanonical(data.searchData.Canonical);
        setNewsRedirect(data.searchData.CustomUrl);
        setNewsSlug(data.searchData.Slug);
        setGalleryContent(data?.staticMediaTitles);
        setLoading(false);

        setLoadingEvents(false);
      });
  }, [language]);

  return (
    <>
      {loading ||
      loadingEvents ||
      loadingSettings ||
      loadingServices ||
      loadingLogo ? (
        <Spinner />
      ) : (
        <div className="app">
          <BrowserRouter>
            <ScrollToTopAfterChangePage />
            <Routes>
              {/**done*/}
              <Route
                path="/:slug?"
                element={
                  <Home
                    loading={loading}
                    websiteTitle={websiteTitle}
                    websiteDesc={websiteDesc}
                    language={language}
                    setLanguage={setLanguage}
                    mainPageHero={mainPageHero}
                    loadingSettings={loadingSettings}
                    servicesData={servicesData}
                    loadingServices={loadingServices}
                    loadingEvents={loadingEvents}
                    events={events}
                    logo={logo}
                    siteName={siteName}
                    loadingLogo={loadingLogo}
                    title={homeTitle}
                    desc={homeDesc}
                    redirect={homeRedirect}
                    slug={homeSlug}
                    canonical={homeCanonical}
                  />
                }
              />
              {/*done*/}
              <Route
                path="/about/:slug?"
                element={
                  <About
                    language={language}
                    setLanguage={setLanguage}
                    logo={logo}
                    siteName={siteName}
                    loadingLogo={loadingLogo}
                  />
                }
              />
              {/**done */}
              <Route
                path="/services/:slug?"
                element={
                  <MedicalServices
                    language={language}
                    setLanguage={setLanguage}
                    servicesData={servicesData}
                    loadingServices={loadingServices}
                    logo={logo}
                    siteName={siteName}
                    loadingLogo={loadingLogo}
                  />
                }
              />
              {/**done */}
              <Route
                path="/services/:id/:slug?"
                element={
                  <Service
                    language={language}
                    setLanguage={setLanguage}
                    servicesData={servicesData}
                    loadingServices={loadingServices}
                    logo={logo}
                    siteName={siteName}
                    loadingLogo={loadingLogo}
                  />
                }
              />
              <Route
                path="/equipment/:id/:slug?"
                element={
                  <Equipment
                    language={language}
                    setLanguage={setLanguage}
                    logo={logo}
                    siteName={siteName}
                    loadingLogo={loadingLogo}
                  />
                }
              />
              {/*done*/}
              <Route
                path="/news/:slug?"
                element={
                  <News
                    newsTitle={newsTitle}
                    newsDesc={newsDesc}
                    newsCanonical={newsCanonical}
                    newsRedirect={newsRedirect}
                    newsSlug={newsSlug}
                    language={language}
                    setLanguage={setLanguage}
                    loadingEvents={loadingEvents}
                    events={events}
                    galleryContent={galleryContent}
                    logo={logo}
                    siteName={siteName}
                    loadingLogo={loadingLogo}
                  />
                }
              />
              {/*done*/}
              <Route
                path="/news/:id/:slug?"
                element={
                  <NewsInside
                    language={language}
                    setLanguage={setLanguage}
                    loadingEvents={loadingEvents}
                    events={events}
                    logo={logo}
                    siteName={siteName}
                    loadingLogo={loadingLogo}
                  />
                }
              />
              {/* <Route
            path="/contact"
            element={
              <ContactUsPage
                language={language}
                setLanguage={setLanguage}
                logo={logo}
                siteName={siteName}
                loadingLogo={loadingLogo}
              />
            }
          /> */}
              {/** done*/}
              <Route
                path="/team"
                element={
                  <MeetOurTeam
                    language={language}
                    setLanguage={setLanguage}
                    logo={logo}
                    siteName={siteName}
                    loadingLogo={loadingLogo}
                  />
                }
              />
              {/*done*/}
              <Route
                path="/doctors/:slug?"
                element={
                  <OurDoctors
                    language={language}
                    setLanguage={setLanguage}
                    loadingLogo={logo}
                    siteName={siteName}
                    logo={logo}
                    title={doctorTitle}
                    desc={doctorDesc}
                    slug={doctorSlug}
                    canonical={doctorCanonical}
                    redirect={doctorRedirect}
                  />
                }
              />
              <Route
                path="/doctors/:id/:slug?"
                element={
                  <DoctorDetails
                    language={language}
                    setLanguage={setLanguage}
                    logo={logo}
                    loadingLogo={loadingLogo}
                    siteName={siteName}
                  />
                }
              />
              <Route
                path="*"
                element={
                  <NotFound
                    language={language}
                    setLanguage={setLanguage}
                    logo={logo}
                    loadingLogo={loadingLogo}
                    siteName={siteName}
                  />
                }
              />
            </Routes>
            <ScrollToTop smooth color="white" width="32" height="50" />
            <ContactUs
              contact_data={contact_data}
              social={social}
              language={language}
              loadingSettings={loadingSettings}
            />
            <Footer
              contact_data={contact_data}
              social={social}
              language={language}
              siteName={siteName}
              logo={logo}
            />
          </BrowserRouter>
        </div>
      )}
    </>
  );
}

export default AppWrap(App);
