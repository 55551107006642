import React from "react";
import Navbar from "../../../components/Navbar/Navbar";
import right from "../../../assets/Layer 1.svg";
import Left from "../../../assets/Layer 2.svg";
function HeroMedical({
  setLanguage,
  language,
  servicesData,
  toggleTap,
  toggle,
  logo,
  siteName,
  loadingLogo,
}) {
  return (
    <div className="hero-about-section heroMedical">
      <div className="container">
        <Navbar
          setLanguage={setLanguage}
          language={language}
          logo={logo}
          siteName={siteName}
          loadingLogo={loadingLogo}
        />
        <div className="mt-10  px-5 lg:px-0">
          <div className="flex justify-center items-center mb-12">
            <div className="flex items-center gap-6">
              <div className="iconContainer">
                <img src={right} alt="" className="img" />
              </div>

              <h2 className="title">
                {servicesData.StaticServicesData.ServiceTitle}
              </h2>
              <div className="iconContainer">
                <img src={Left} alt="" className="img" />
              </div>
            </div>
          </div>

          <p className="medicalServices__paragraph text-center">
            {servicesData?.StaticServicesData?.ServicesDes}
          </p>
          <div className="medicalServices__btns  flex-center">
            <button
              className={toggle === 1 ? "btn-fill" : "btn-fill notActive"}
              onClick={() => toggleTap(1)}
            >
              {language === "en" ? "Medical Services" : "الخدمات الطبية"}
            </button>
            <button
              className={toggle === 2 ? "btn-fill" : "btn-fill notActive"}
              onClick={() => toggleTap(2)}
            >
              {language === "en" ? "Equipment & Units" : "المعدات والوحدات"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeroMedical;
