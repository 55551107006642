import { useEffect, useState } from 'react';
import './Gallery.css';
import GalleryCard from './GalleryCard/GalleryCard';
import GallerySwiper from './GallerySwiper/GallerySwiper';
import right from '../../assets/Layer 1.svg';
import Left from '../../assets/Layer 2.svg';
const url_main = 'https://admin.elshoroukhospital.com';
function Gallery({ language, galleryContent }) {
	const [galleryBtns, setGalleryBtns] = useState([]);
	const [toggle, setToggle] = useState(1);
	useEffect(() => {
		fetch(`${url_main}/api/gallaryMedia`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				lang: language,
			},
		})
			.then((res) => {
				return res.json();
			})
			.then((data) => {
				setGalleryBtns(data);
			});
	}, [language]);
	const toggleTap = (index) => {
		setToggle(index);
	};
	return (
		<div className='gallery container mt-20'>
			<div className='flex justify-center items-center mb-12'>
				<div className='flex items-center gap-6'>
					<div className='iconContainer'>
						<img src={right} alt='' className='img' />
					</div>

					<h2 className='title'>{galleryContent?.gallaryMediaTitle}</h2>
					<div className='iconContainer'>
						<img src={Left} alt='' className='img' />
					</div>
				</div>
			</div>

			<p className='gallery__paragraph mt-10 text-center'>{galleryContent?.gallaryMediaDes}</p>
			<div className='flex gallery__hover__div px-5 lg:px-0'>
				{galleryBtns?.slice(0, 8)?.map((item, index) => (
					<GalleryCard key={index} item={item} />
				))}
			</div>

			<div className='gallery__btns flex mt-24 flex-wrap px-5 lg:px-0 gap-3'>
				{galleryBtns?.map((item, index) => (
					<button key={index} onClick={() => toggleTap(item.id)} className={` hover:shadow-lg shadow-gray-800 ${toggle === item.id ? 'btn-fill' : 'btn-fill notActive'}`}>
						{item.categoryName}
					</button>
				))}
			</div>
			<div className='px-5 lg:px-0'>
				<GallerySwiper toggle={toggle} language={language} />
			</div>
		</div>
	);
}

export default Gallery;
