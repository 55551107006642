import Spinner from "../../../components/Spinner/Spinner";
import EventsNewsCard from "../../News/EventsNews/EventsNewsCard";
import right from "../../../assets/Layer 1.svg";
import Left from "../../../assets/Layer 2.svg";
function RelatedNews({ language, loadingEvents, events, newsSlug }) {
  return (
    <>
      {loadingEvents ? (
        <Spinner />
      ) : (
        <div className="mt-20 mb-24 px-5 lg:px-0">
          <div className="container">
            <div className="flex justify-center items-center mb-12">
              <div className="flex items-center gap-6">
                <div className="iconContainer">
                  <img src={right} alt="" className="img" />
                </div>

                <h2 className="title">
                  {language === "en" ? "Related News" : "اخبار ذات صلة"}
                </h2>
                <div className="iconContainer">
                  <img src={Left} alt="" className="img" />
                </div>
              </div>
            </div>

            <div className="flex related__div">
              {events?.news_events?.slice(0, 3)?.map((item, index) => (
                <EventsNewsCard
                  item={item}
                  key={index}
                  type="related"
                  newsSlug={newsSlug}
                  language={language}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default RelatedNews;
