import React from 'react';
import vision4 from '../../../../assets/vision/vision4.png';
import vision5 from '../../../../assets/vision/vision5.png';

import ConvertHtmlToText from '../../../../components/convertingHtml/ConvertHtmlToText';
function AboutVisionCard({ item, language }) {
	function truncate(string, n) {
		return string?.length > n ? string.substr(0, n - 1) + '...' : string;
	}
	console.log(item);
	return (
		<div className='aboutVisionCard mb-14'>
			<div className='aboutVisionCard__div__image'>
				<div className='aboutVisionCard__div__image__inside flex-center'>
					<img src={item.icon} alt='icon' loading='lazy' className='aboutVisionCard__div__image__inside__mainImage' />
					<img src={vision5} alt='icon' loading='lazy' className='aboutVisionCard__div__image__inside__iconImageTop' />
					<img src={vision4} alt='icon' loading='lazy' className='aboutVisionCard__div__image__inside__iconImageBottom' />
				</div>
			</div>
			<div className='aboutVisionCard__div__content'>
				<h3>{item.title}</h3>
				<p>
					<ConvertHtmlToText html={item.desc} />
				</p>
			</div>
		</div>
	);
}

export default AboutVisionCard;
