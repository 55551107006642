import React, { useEffect, useState } from "react";
import style from "./serv.module.css";
import Navbar from "../../components/Navbar/Navbar";
import { useParams, useNavigate } from "react-router-dom";
import Spinner from "../../components/Spinner/Spinner";
import Meta from "../../components/meta/Meta";

const Service = ({ setLanguage, language, logo, siteName, loadingLogo }) => {
  const navigate = useNavigate();
  const url_main = "https://admin.elshoroukhospital.com";
  const { id } = useParams();
  const [servicesTitle, setServicesTitle] = useState("");
  const [servicesDesc, setServicesDesc] = useState("");
  const [servicesCanonical, setServicesCanonical] = useState("");
  const [servicesRedirect, setServicesRedirect] = useState("");
  const [servicesSlug, setServicesSlug] = useState("");
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  // get data from api
  useEffect(() => {
    fetch(`${url_main}/api/services/${parseInt(id)}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        lang: language,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        console.log("this is the data from services page", data);
        setData(data);
        setServicesTitle(data.searchTitle);
        setServicesDesc(data.searchMetaDes);
        setServicesSlug(data.searchSlug);
        setServicesRedirect(data.searchCustomUrl);
        setServicesCanonical(data.searchCanonical);
        setLoading(false);
      });
  }, [language, id]);

  // handle redirect
  useEffect(() => {
    if (!loading && servicesRedirect) {
      window.location.href = servicesRedirect;
    }
  }, [loading, servicesRedirect]);

  //  handle slug
  useEffect(() => {
    const currentSlug = window.location.pathname.split("/").pop();
    console.log("this is the current slug", currentSlug);
    if (!loading && servicesSlug) {
      if (currentSlug !== servicesSlug) {
        navigate(`/services/${id}/${servicesSlug}`);
      }
    }
  }, [loading, navigate, servicesSlug, id]);
  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <div>
          <Meta
            title={servicesTitle}
            desc={servicesDesc}
            canonical={servicesCanonical}
          />
          <div className={style.mainContainer}>
            <div className="container mb-5">
              <Navbar
                setLanguage={setLanguage}
                language={language}
                logo={logo}
                siteName={siteName}
                loadingLogo={loadingLogo}
              />
              <div className="mt-5 pt-4 row justify-content-center">
                <div className="col-12 col-md-10">
                  <h3 className={`m-0 p-0 mb-3 text-center ${style.title}`}>
                    {data.title}
                  </h3>

                  <img
                    loading="lazy"
                    alt={data.ImgAlt}
                    className={style.mainImg}
                    src={data.image}
                  />
                  <p className="m-0 p-0 mt-3 text-black-50">
                    {data.description}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Service;
