import React from 'react'

const ConvertHtmlToText = (props) => {
    const removeHtmlTags = (text) => {
        return text.replace(/<[^>]*>/g, '')
    }
    const text = removeHtmlTags(props.html) ; 
  return (
    <div style={{whiteSpace : 'pre-wrap'}} >
            {text}
    </div>
  )
}

export default ConvertHtmlToText