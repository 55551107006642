import React from "react";

const SearchBar = ({ language, setTitleValue }) => {
  return (
    <div className="flex flex-col md:flex-row justify-center md:justify-between items-center gap-6">
      <div className="text-center">
        <label
          htmlFor="select"
          className="font-bold text-white  text-md md:mx-3"
        >
          {language === "en" ? "Search By Specialist" : "ابحث بالتخصص"}
        </label>
        <select
          onChange={(e) => setTitleValue(e.target.value)}
          className="w-80 md:w-96 block mt-4 md:mt-0 md:inline-block focus:outline-none p-1  rounded-md pr-16"
        >
          <option value="">Select</option>
          <option value="professor">Professor</option>
          <option value="consultant">Consultant</option>
          <option value="specialist">Specialist</option>
        </select>
      </div>
      <button className="text-white font-bold text-base bg-[#00c4f1] w-22 h-2 rounded-[33px] p-4 flex justify-center items-center">
        {language === "en" ? "Search Now" : "ابحث الان"}
      </button>
    </div>
  );
};

export default SearchBar;
/*
  	<select name='' className=' p-4 h-3 w-96 rounded-[33px] focus:outline-none' >
					<option value='' className = 'text-black'>Select</option>
					<option value='' className = 'text-black'>one</option>
					<option value='' className = 'text-black'>two</option>
					<option value='' className = 'text-black'>three</option>
				</select>
*/
