import React from "react";
import { Link } from "react-router-dom";
import Spinner from "../../../components/Spinner/Spinner";
import ConvertHtmlToText from "../../../components/convertingHtml/ConvertHtmlToText";
import right from '../../../assets/Layer 1.svg'
import Left from '../../../assets/Layer 2.svg'
function HeroAboutContent({ language, aboutData, loadingAbout }) {
  return (
		<>
			{loadingAbout ? (
				<Spinner />
			) : (
				<div className='flex-center flex-column heroAboutContent px-5 lg:px-0'>
					<div className='flex justify-center items-center mb-6'>
						<div className='flex items-center gap-6'>
							<div className='iconContainer'>
								<img src={right} alt='' className='img' />
							</div>

							<h2 className='title'>{language === 'en' ? 'About us' : 'من نحن'}</h2>
							<div className='iconContainer'>
								<img src={Left} alt='' className='img' />
							</div>
						</div>
					</div>
					
					<div className='my-5 text-center'>
						<ConvertHtmlToText html={aboutData?.aboutus?.Description} />
					</div>
					<Link to='/team'>
						<button className='btn-fill'>{language === 'en' ? 'Meet Our Team' : 'تعرف على فريقنا'}</button>
					</Link>
				</div>
			)}
		</>
  );
}

export default HeroAboutContent;
