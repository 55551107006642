import React from 'react';
import AboutVisionCard from './AboutVisionCard';
import Spinner from '../../../../components/Spinner/Spinner';
import elipse from '../../../../assets/Ellipse 116.svg';
function AboutVision({ aboutData, loadingAbout }) {
	return (
		<>
			{loadingAbout ? (
				<Spinner />
			) : (
				<div>
					<div className='elipseContainer'>
						<img src={elipse} alt='' className='elipse' />
					</div>
					<img src={elipse} alt='' className='elipseTwo' />
					<div className='aboutVision container px-5 lg:px-0'>
						{aboutData?.aboutus?.boxes?.map((item, index) => (
							<AboutVisionCard key={index} item={item} />
						))}
					</div>
				</div>
			)}
		</>
	);
}

export default AboutVision;
