import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Spinner from "../../components/Spinner/Spinner";
import HeroNewsInside from "./HeroNewsInside/HeroNewsInside";
import NewsInsideMainContent from "./HeroNewsInside/NewsInsideMainContent";
import RelatedNews from "./HeroNewsInside/RelatedNews";
import "./NewsInside.css";
import Meta from "../../components/meta/Meta";
const url_main = "https://admin.elshoroukhospital.com";

function NewsInside({
  setLanguage,
  language,
  loadingEvents,
  events,
  logo,
  siteName,
  loadingLogo,
}) {
  const param = useParams();
  const navigate = useNavigate();
  const [articleInside, setarticleInside] = useState({});
  const [loadingArticle, setLoadingArticle] = useState(true);
  const [newsTitle, setNewsTitle] = useState("");
  const [newsDesc, setNewsDesc] = useState("");
  const [newsCanonical, setNewsCanonical] = useState("");
  const [newsRedirect, setNewsRedirect] = useState("");
  const [newsSlug, setNewsSlug] = useState("");
  useEffect(() => {
    fetch(`${url_main}/api/newsEvents/${parseInt(param.id)}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        lang: language,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setarticleInside(data);
        setNewsTitle(data.searchTitle);
        setNewsDesc(data.searchMetaDes);
        setNewsCanonical(data.searchCanonical);
        setNewsSlug(data.searchSlug);
        setNewsRedirect(data.searchCustomUrl);
        setLoadingArticle(false);
      });
  }, [language, param.id]);
  // Redirect after the data has been loaded and aboutRedirect is not empty
  useEffect(() => {
    if (!loadingArticle && newsRedirect) {
      window.location.href = newsRedirect;
    }
  }, [loadingArticle, newsRedirect]);

  useEffect(() => {
    const currentSlug = window.location.pathname.split("/").pop();
    console.log("this is the current slug", currentSlug);
    if (!loadingArticle && newsSlug) {
      if (currentSlug !== newsSlug) {
        navigate(`/news/${param.id}/${newsSlug}`);
      }
    }
  }, [loadingArticle, navigate, newsSlug, param.id]);
  return (
    <>
      {loadingArticle ? (
        <Spinner />
      ) : (
        <div>
          <Meta title={newsTitle} desc={newsDesc} canonical={newsCanonical}  />
          <HeroNewsInside
            language={language}
            setLanguage={setLanguage}
            articleInside={articleInside}
            logo={logo}
            siteName={siteName}
            loadingLogo={loadingLogo}
          />
          <NewsInsideMainContent
            language={language}
            articleInside={articleInside}
            loadingArticle={loadingArticle}
          />
          <RelatedNews
            language={language}
            loadingEvents={loadingEvents}
            events={events}
            newsSlug={newsSlug}
          />
        </div>
      )}
    </>
  );
}

export default NewsInside;
