import React from 'react';
import style from './Appointment.module.css';
import Left from '../../../assets/Layer 2.svg';
import right from '../../../assets/Layer 1.svg';
const Appointments = ({ language, availableDays }) => {
	
	return (
		<div>
			<div className='flex justify-center items-center'>
				<div className='flex items-center gap-6'>
					<div className={style.iconContainer}>
						<img src={right} alt='' className={style.img} />
					</div>

					<h2 className={style.title}>{language === 'en' ? 'Doctor Appointments' : 'مواعيد الأطباء'}</h2>
					<div className={style.iconContainer}>
						<img src={Left} alt='' className={style.img} />
					</div>
				</div>
			</div>
			<div className='flex flex-col md:flex-row items-center justify-center gap-8 my-16'>
				{availableDays.map((item, index) => (
					<div key={index} className='bg-white h-28  shadow-lg   rounded-md p-4'>
						<div className='flex items-center gap-6'>
							<p className=' font-bold text-2xl text-center text-[#566099]'>{item.day}</p>

							<div className='text-center'>
								<p className='text-[#193569] mb-1 text-base'>{language === 'en' ? 'Visits Starts from' : 'الزيارات تبدأ من'} </p>
								<div className='flex items-center gap-2'>
									<div className='w-3 h-3 rounded-[50%] bg-[#00ABDB]'></div>
									<p className='text-[#193569]'>
										{item.fromHour} - {item.toHour}
									</p>
								</div>
							</div>
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default Appointments;
/*
  <div className={`${style.titleDiv}`}>
				<h2 className={style.title}>{language === 'en' ? 'Doctor Appointments' : 'مواعيد الأطباء'}</h2>
			</div>
*/
