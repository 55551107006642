import React from "react";
import { AiOutlineArrowRight, AiOutlineArrowLeft } from "react-icons/ai";
import { Link } from "react-router-dom";
const DoctorsDetails = ({ item, language }) => {
  const url_main = "https://el-shrouk-hospital-dashboard.technomasrsystems.com";

  return (
    <div className="bg-white p-3 shadow relative mb-20 rounded-3xl">
      <img
        src={item.image}
        alt={item.ImgAlt}
        className="w-36 h-36 object-cover absolute rounded-3xl top-[-20%] left-[50%] translate-x-[-50%]"
      />
      <h5 className="mt-28 text-[#4d658b] text-center font-bold ">
        {item.name}
      </h5>
      <p className="my-4 text-center">{item.job_title}</p>

      <Link
        to={`/doctors/${item.id}`}
        className="flex gap-2 items-center  justify-end text-[#50C6DF] cursor-pointer hover:text-[#4d658b] duration-300 uppercase"
      >
        <span>{language === "en" ? "SHOW MORE" : "شاهد المزيد"}</span>
        {language === "ar" ? (
          <AiOutlineArrowLeft size={20} className="" />
        ) : (
          <AiOutlineArrowRight size={20} className="" />
        )}
      </Link>
    </div>
  );
};

export default DoctorsDetails;
