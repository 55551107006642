import React, { useEffect, useState } from "react";
import HeroAbout from "./HeroAbout/HeroAbout";
import "./About.css";
import right from "../../assets/Layer 1.svg";
import Left from "../../assets/Layer 2.svg";
import AboutVision from "./HeroAbout/AboutVision/AboutVision";
import Spinner from "../../components/Spinner/Spinner";
import Meta from "../../components/meta/Meta";
import { useNavigate, useParams } from "react-router-dom";

const url_main = "https://admin.elshoroukhospital.com";

function About({ setLanguage, language, logo, siteName, loadingLogo }) {
  const navigate = useNavigate();
  const { slug } = useParams();

  // const match = useMatch("/about/:slug");
  // const { slug } = match.params;
  // To Fetch About
  const [loadingAbout, setLoadingAbout] = useState(true);
  const [aboutData, setAboutData] = useState([]);
  const [aboutTitle, setAboutTitle] = useState("");
  const [aboutDesc, setAboutDesc] = useState("");
  const [aboutCanonical, setAboutCanonical] = useState("");
  const [aboutRedirect, setAboutRedirect] = useState("");
  const [aboutSlug, setAboutSlug] = useState("");
  useEffect(() => {
    fetch(`${url_main}/api/aboutus`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        lang: language,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        console.log("aboutData", data.searchData);
        setAboutData(data);
        setAboutDesc(data.searchData.Meta);
        setAboutRedirect(data.searchData.CustomUrl);
        setAboutCanonical(data.searchData.Canonical);
        setAboutTitle(data.searchData.Title);
        setAboutSlug(data.searchData.Slug);
        setLoadingAbout(false);
      });
  }, [language]);
  // handle redirect to another page
   useEffect(() => {
     if (!loadingAbout && aboutRedirect) {
      window.location.href = aboutRedirect;
     }
  }, [aboutRedirect, loadingAbout]);
  useEffect(() => {
    const currentSlug = window.location.pathname.split("/").pop();
    console.log("this is the current slug", currentSlug);
    if (!loadingAbout && aboutSlug) {
      if (currentSlug !== aboutSlug) {
        navigate(`/about/${aboutSlug}`);
      }
    }
  }, [loadingAbout, navigate, aboutSlug]);
  return (
    <>
      <Meta title={aboutTitle} desc={aboutDesc} canonical={aboutCanonical} />
      {loadingAbout ? (
        <Spinner />
      ) : (
        <div className="about">
          <HeroAbout
            setLanguage={setLanguage}
            language={language}
            aboutData={aboutData}
            loadingAbout={loadingAbout}
            logo={logo}
            siteName={siteName}
            loadingLogo={loadingLogo}
          />
          <AboutVision
            aboutData={aboutData}
            loadingAbout={loadingAbout}
            language={language}
          />
          <div className="medicalServices__loaction container">
            <div className="flex justify-center items-center mb-12">
              <div className="flex items-center gap-6">
                <div className="iconContainer">
                  <img src={right} alt="" className="img" />
                </div>

                <h2 className="title">
                  {language === "en" ? "Location" : "موقعنا"}
                </h2>
                <div className="iconContainer">
                  <img src={Left} alt="" className="img" />
                </div>
              </div>
            </div>

            <div className="medicalServices__mapImage px-5 lg:px-0">
              <img
                src={aboutData?.imageMap}
                alt="map"
                className="rounded-sm object-cover"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default About;
