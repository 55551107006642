import React, { useState, useEffect } from "react";
import Spinner from "../../../components/Spinner/Spinner";
import { useParams } from "react-router-dom";
const url_main = "https://admin.elshoroukhospital.com";
function NewsInsideMainContent({ language }) {
  const [selectedImage, setSelectedImage] = useState(0);

  const [articleInside, setarticleInside] = useState({});

  const [loading, setLoading] = useState(true);
  const params = useParams();
  function renderDescription(description) {
    if (!description) {
      return null;
    }

    // Create a new DOMParser
    const parser = new DOMParser();
    const doc = parser.parseFromString(description, "text/html");

    // Find all list items and wrap them in a <ul> with list-style-type: disc
    const listItems = doc.querySelectorAll("li");
    listItems.forEach((li) => {
      const ul = document.createElement("ul");
      ul.style.listStyleType = "disc";
      li.parentNode.replaceChild(ul, li);
      ul.appendChild(li);
      ul.classList.add('tessst')
    });

    // Render the modified HTML as React components using dangerouslySetInnerHTML
    return <div  dangerouslySetInnerHTML={{ __html: doc.body.innerHTML }} />;
  }

  useEffect(() => {
    const fetchData = async () => {
      const res = await fetch(`${url_main}/api/newsEvents/${params.id}`, {
        method: "GET",
        headers: {
          lang: language,
        },
      });
      const data = await res.json();

      if (data) {
        setarticleInside(data);
        setLoading(false);
      }
    };
    fetchData();
  }, [params.id, language]);
  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <div className="newsInsideMainContent container px-5 ">
          <div className="heroNewsInsideContent__images mt-2">
            <div className="heroNewsInsideContent__images__selected">
              <img
                src={articleInside.images[selectedImage]}
                alt={articleInside?.ImgAlt}
                className="image__mainStyle md:rounded-lg"
              />
            </div>
            <div className="heroNewsInsideContent__images__right">
              {articleInside.images.map((item, index) => (
                <img
                  key={index}
                  src={item}
                  alt={item.ImgAlt}
                  onClick={() => setSelectedImage(index)}
                  className={index === selectedImage ? "" : "some-blur"}
                />
              ))}
            </div>
          </div>
          <div>
            <div className="flex gap-4 mt-4">
              <span>
                <span className="circule"></span> By : Admin
              </span>
              <span>
                <span className="circule"></span>
                {articleInside.created_at}
              </span>
            </div>
            <div>
              <ul
                style={{
                  listStyleType: "disc",
                }}
              >
                {renderDescription(articleInside?.description)}
              </ul>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default NewsInsideMainContent;
/*
  		{loadingArticle ? (
				<Spinner />
			) : (
				<div className='newsInsideMainContent container px-5 lg:px-0'>
					<div className='heroNewsInsideContent__images mt-2'>
						<div className='heroNewsInsideContent__images__selected'>
							<img src={articleInside.images[selectedImage]} alt={articleInside?.title} className='image__mainStyle md:rounded-lg' />
						</div>
						<div className='heroNewsInsideContent__images__right'>
							{articleInside.images.map((item, index) => (
								<img key={index} src={item} alt='news' onClick={() => setSelectedImage(index)} className={index === selectedImage ? '' : 'some-blur'} />
							))}
						</div>
					</div>
					<div className='newsInsideMainContent__allParagraph'>
						<div className='flex gap-4 mt-4'>
							<span>
								<span className='circule'></span> By : Admin
							</span>
							<span>
								<span className='circule'></span>
								{articleInside.created_at}
							</span>
						</div>
						<div dangerouslySetInnerHTML={{ __html: articleInside.description }} />
					</div>
				</div>
			)}
*/
