import React from "react";
import style from "./notFound.module.css";
import Lottie from "react-lottie";
import animationData from "../../assets/404.json";
import Navbar from "../../components/Navbar/Navbar";
import { Link } from "react-router-dom";
const NotFound = ({ setLanguage, language, logo, siteName, loadingLogo }) => {
  const defaultOptions = {
    loop: true, // Set to true if you want the animation to loop
    autoplay: true, // Set to true if you want the animation to start playing as soon as it's mounted
    animationData: animationData, // The animation data you imported
  };
  const customStyle = {
    width: "350px", // Specify the desired width
    height: "350px", // Specify the desired height
  };
  return (
    <div className={style.mainContainer}>
      <div className="container mb-5 ">
        <Navbar
          setLanguage={setLanguage}
          language={language}
          logo={logo}
          siteName={siteName}
          loadingLogo={loadingLogo}
        />
        <div className="mt-5  pt-5">
          <div className="flex flex-col  justify-center items-center flex-wrap">
            <div>
              <Lottie style={customStyle} options={defaultOptions} />
            </div>
            <div className={` flex flex-col items-center gap-2`}>
              <h4 className="mb-3 color">OOPS !! page not found</h4>
              <button className="btn-fill">
                <Link to="/">go to home page</Link>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
