import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import style from "../ourDoctors/OurDoctors.module.css";
import Navbar from "../../components/Navbar/Navbar";
import Appointments from "../../components/OurDoctors/Appointments/Appointments";
import RelatedDoctors from "../../components/OurDoctors/RelatedDoctors/RelatedDoctors";
import Spinner from "../../components/Spinner/Spinner";
import Meta from "../../components/meta/Meta";
import { useNavigate } from "react-router-dom";
const DoctorDetails = ({ language, logo, setLanguage, siteName }) => {
  const url_main = "https://admin.elshoroukhospital.com";
  const navigate = useNavigate();
  const { id } = useParams();
  const [doctor, setDoctors] = useState({});
  const [relatedDoctors, setRelatedDoctors] = useState([]);
  const [availableDays, setAvailableDays] = useState([]);
  const [formLink, setFormLink] = useState("");
  const [loading, setLoading] = useState(true);
  const [doctorTitle, setDoctolTitle] = useState("");
  const [doctorDesc, setDoctorDesc] = useState("");
  const [doctorCanonical, setDoctorCanonical] = useState("");
  const [doctorSlug, setDoctorSlug] = useState("");
  const [doctorRedirect, setDoctorRedirect] = useState("");
  useEffect(() => {
    const fetchDoctorDetails = async () => {
      const res = await fetch(`${url_main}/api/doctor/${id}`, {
        method: "GET",
        headers: {
          lang: language,
        },
      });
      const data = await res.json();
      if (data.status) {
        setDoctors(data.data);
        setAvailableDays(data.data.appointments);
        setRelatedDoctors(data.relatedDoctors);
        setFormLink(data.data.formLink);
        setDoctolTitle(data.data.searchTitle);
        setDoctorDesc(data.data.searchMetaDes);
        setDoctorCanonical(data.data.searchCanonical);
        setDoctorRedirect(data.data.searchCustomUrl);
        setDoctorSlug(data.data.searchSlug);
        setLoading(false);
      }
      console.log("doctors details", data);
    };
    fetchDoctorDetails();
  }, [language, id]);

  // handle redirect
  useEffect(() => {
    if (!loading && doctorRedirect) {
      window.location.href = doctorRedirect;
    }
  }, [loading, doctorRedirect]);

  //  handle slug
  useEffect(() => {
    const currentSlug = window.location.pathname.split("/").pop();
    if (!loading && doctorSlug) {
      if (currentSlug !== doctorSlug) {
        navigate(`/doctors/${id}/${doctorSlug}`);
      }
    }
  }, [loading, navigate, doctorSlug, id]);
  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <Meta
            title={doctorTitle}
            desc={doctorDesc}
            canonical={doctorCanonical}
          />
          <div className={style.OurDoctorsContainer}>
            <div className="container">
              <Navbar
                logo={logo}
                language={language}
                setLanguage={setLanguage}
                siteName={siteName}
              />
              <div className="mt-16 md:mt-72">
                <div className="text-white rounded-[33px]  w-auto mx-auto shadow-slate-500 p-2 shadow-xl z-50">
                  <div className=" flex justify-between  flex-col md:flex-row">
                    <div className="flex flex-col md:flex-row md:gap-8 items-center">
                      <img
                        src={doctor.image}
                        alt={doctor.ImgAlt}
                        className="w-60  object-cover rounded-[33px] relative -top-10"
                      />
                      <div className="">
                        <h5 className=" text-3xl text-[#4d658b] font-bold">
                          {doctor.name}
                        </h5>
                        <p className="text-[#4d658b] my-4">
                          {doctor.job_title}
                        </p>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: doctor.description,
                          }}
                        ></p>
                      </div>
                    </div>
                    <button className="btn-fill  h-14">
                      <a
                        href={formLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {language === "en" ? "Book Now" : "احجز الان"}
                      </a>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="my-24">
              <Appointments availableDays={availableDays} language={language} />
            </div>
            {relatedDoctors.length > 0 ? (
              <div className="my-24 max-w-[1640px] mx-auto px-6">
                <RelatedDoctors
                  relatedDoctors={relatedDoctors}
                  language={language}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </>
      )}
    </>
  );
};

export default DoctorDetails;

/*
    	<div className='container'>
				<Navbar logo={logo} language={language} setLanguage={setLanguage} siteName={siteName} />
				<div className='my-24'>
					<div className='text-white rounded-[33px] p-4  shadow-md'>
						<div className='flex gap-8 items-center'>
							<img src={data.img} alt='' className=' w-80 h-80 object-cover rounded-[33px] relative -top-16' />
							<div className=''>
								<h5 className=' text-3xl text-[#4d658b] font-bold'>{data.title}</h5>
								<p className='text-[#4d658b]'>{data.desc}</p>
								<p className='mt-8 text-[#4d658b]'>{data.content}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
*/
