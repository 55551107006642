import React, { useState } from "react";
import "./News.css";
import { HiArrowNarrowRight, HiArrowNarrowLeft } from "react-icons/hi";
import Spinner from "../Spinner/Spinner";
import right from "../../assets/Layer 1.svg";
import Left from "../../assets/Layer 2.svg";
import { Link } from "react-router-dom";
const url_main = "https://el-shrouk-hospital-dashboard.technomasrsystems.com";
function News({ language, events, loadingEvents }) {
  return (
    <>
      <div className="news">
        <div className="container">
          <div className="flex justify-center items-center mb-12">
            <div className="flex items-center gap-6">
              <div className="iconContainer">
                <img src={right} alt="" className="img" />
              </div>

              <h2 className="title">
                {language === "en" ? "News & Media" : "وسائل الإعلام"}
              </h2>
              <div className="iconContainer">
                <img src={Left} alt="" className="img" />
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-4 my-14 gap-8">
            {events.news_events.map((item, index) => (
              <div
                key={index}
                className={`${
                  index === 0
                    ? "md:row-span-4 md:col-span-2 md:h-[415px]"
                    : index === 3
                    ? "md:col-span-2"
                    : ""
                } duration-300 hover:scale-105 relative    group hover:bg-[#3C8BCB] h-48 overflow-hidden`}
              >
                <img
                  src={item.images}
                  alt={item.ImgAlt}
                  className=" w-3/4 mx-auto md:w-full md:h-full object-cover group-hover:opacity-10"
                />
                <div className="hidden group-hover:block absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] text-white w-full px-2">
                  <div className=" flex justify-between items-center">
                    <p className="text-white">{item.created_at}</p>
                    <Link to={`/news/${item.id}`}>
                      <HiArrowNarrowRight size={20} />
                    </Link>
                  </div>
                  <div className="my-5 flex justify-center items-center">
                    <p className="text-center text-white">{item.title}</p>
                  </div>

                  <p
                    dangerouslySetInnerHTML={{
                      __html: item?.description?.slice(0, 80),
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default News;
/*
  	<div className='news__images'>
							<div className='news__images__left'>
								<img src={`${url_main}/uploads/news/${events?.news_events[0]?.id}/${events?.news_events[0]?.images}`} alt='news' className='news__image__style' />
							</div>
							<div className='news__images__right '>
								<div className='news__images__right__top relative ' onMouseEnter={() => setNewsHover(true)} onMouseLeave={() => setNewsHover(false)}>
									{' '}
									<img src={`${url_main}/uploads/news/${events?.news_events[1]?.id}/${events?.news_events[1]?.images}`} alt='news' className='news__image__style' />
									{newsHover && (
										<>
											<div className='eventsNews__main__content absolute bottom-0 z-10 w-full p-2 md:p-4 bg-[#007aff] h-full  text-center  opacity-50 border-t-4 border-l-4 border-l-[red] border-t-[red]'>
												<h3 className='text-[yellow]'>{events?.news_events[1].title}</h3>
												<div className='flex justify-between mt-2'>
													<div className='font-bold flex gap-4 text-xs'>
														<span className='text-[yellow]'>By : Admin</span>
														<span className='text-[yellow]'>{events?.news_events[1].created_at}</span>
													</div>
													<Link to={`/news/${events?.news_events[1]?.id}`}>
														<button className='flex items-center text-xs font-bold gap-2 text-[yellow]'>
															{language === 'en' ? 'Read More' : 'اقرأ المزيد'}
															{language === 'en' ? <HiArrowNarrowRight className='text-lg ' /> : <HiArrowNarrowLeft className='text-lg' />}
														</button>
													</Link>
												</div>
											</div>
											<div className='gradient' />
										</>
									)}
								</div>
								<div className='news__images__right__bottom'>
									<div>
										{' '}
										<img src={`${url_main}/uploads/news/${events?.news_events[2]?.id}/${events?.news_events[2]?.images}`} alt='news' className='news__image__style' />
									</div>
									<div>
										{' '}
										<img src={`${url_main}/uploads/news/${events?.news_events[3]?.id}/${events?.news_events[3]?.images}`} alt='news' className='news__image__style' />
									</div>
								</div>
							</div>
						</div>
*/
/**
 * 
 * <img
									src={`${url_main}/uploads/news/${item.id}/${item.images}`}
									alt='fsdf'
									className={`${
										index === 0 ? ' col-span-2 row-span-2' : index === 3 ? 'col-span-2' : ''
									} w-full h-48 object-cover   group hover:bg-gradient-to-r from-[#5651e5] to-[#709dff] `}
								/>
 */
/*
                  <div className='flex-center'>
						<div className='main__title-div'>
							<h2 className='main__title'>{language === 'en' ? 'News & Media' : 'وسائل الإعلام'}</h2>
						</div>
					</div>
*/
