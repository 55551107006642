import React, { useState, useEffect } from "react";
import style from "./OurDoctors.module.css";
import Navbar from "../../components/Navbar/Navbar";
import SearchBar from "../../components/OurDoctors/SearchBar";
import FilterSideBar from "../../components/OurDoctors/FilterSideBar";
import DoctorsDetails from "../../components/OurDoctors/DoctorsDetails";
import Spinner from "../../components/Spinner/Spinner";
import Meta from "../../components/meta/Meta";
import { useNavigate } from "react-router-dom";
const OurDoctors = ({
  language,
  setLanguage,
  logo,
  siteName,
  loadingLogo,
  title,
  desc,
  slug,
  canonical,
  redirect,
}) => {
  console.log("doctor desc", desc);
  const url_main = "https://admin.elshoroukhospital.com";
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [allTeams, setAllTeams] = useState([]);
  const [dateValue, setDateValue] = useState("");
  const [genderValue, setGenderValue] = useState("");
  const [titleValue, setTitleValue] = useState("");
  // Redirect after the data has been loaded and aboutRedirect is not empty

  useEffect(() => {
    const getDoctors = async () => {
      const res = await fetch(
        `${url_main}/api/doctors/filters?day=${dateValue}&gender=${genderValue}&title=${titleValue}`,
        {
          method: "GET",
          headers: {
            lang: language,
            "Content-Type": "application/json",
          },
        }
      );
      const data = await res.json();

      if (data) {
        console.log("this is the data from our doctor page", data);
        setAllTeams(data.data);
        setLoading(false);
      } else {
        setLoading(true);
      }
    };
    getDoctors();
  }, [language, genderValue, titleValue, dateValue]);
  useEffect(() => {
    if (redirect) {
      window.location.href = redirect;
    }
  }, [redirect]);

  useEffect(() => {
    const currentSlug = window.location.pathname.split("/").pop();
    console.log("this is the current slug", currentSlug);
    if (slug) {
      if (currentSlug !== slug) {
        navigate(`/doctors/${slug}`);
      }
    }
  }, [navigate, slug]);
  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <div className={style.OurDoctorsContainer}>
          <Meta title={title} desc={desc} canonical={canonical} />
          <div>
            <div className="container">
              <Navbar
                logo={logo}
                language={language}
                setLanguage={setLanguage}
                siteName={siteName}
              />
              {/*start search bar*/}
            </div>
            <div>
              <div className=" w-full bg-[#59639C] h-auto py-4 flex justify-center items-center">
                <div className="container ">
                  <SearchBar
                    language={language}
                    setTitleValue={setTitleValue}
                  />
                </div>
              </div>
              <div className="container my-12 md:my-24 ">
                <div className="flex flex-col md:flex-row gap-24 md:gap-8">
                  <div className=" w-screen md:w-1/4">
                    <FilterSideBar
                      language={language}
                      setDateValue={setDateValue}
                      setGenderValue={setGenderValue}
                      setTitleValue={setTitleValue}
                    />
                  </div>
                  <div className="w-screen md:w-3/3">
                    {loading ? (
                      <div className="flex justify-center items-center">
                        <Spinner />
                      </div>
                    ) : (
                      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                        {allTeams.map((item, index) => (
                          <DoctorsDetails
                            language={language}
                            key={index}
                            item={item}
                          />
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default OurDoctors;
