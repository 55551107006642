import React from "react";
import Navbar from "../../../components/Navbar/Navbar";
import right from '../../../assets/Layer 1.svg'
import Left from '../../../assets/Layer 2.svg'
function HeroNews({
  setLanguage,
  language,
  events,
  logo,
  siteName,
  loadingLogo,
}) {
  return (
		<div className='hero-about-section heroNews__customHeight'>
			<div className='container'>
				<Navbar setLanguage={setLanguage} language={language} logo={logo} siteName={siteName} loadingLogo={loadingLogo} />
				<div className='flex-center flex-column heroAboutContent px-5 lg:px-0'>
					<div className='flex justify-center items-center'>
						<div className='flex items-center gap-6'>
							<div className='iconContainer'>
								<img src={right} alt='' className='img' />
							</div>

							<h2 className='title'>{events?.staticNewsTitles?.newsEventsTitle}</h2>
							<div className='iconContainer'>
								<img src={Left} alt='' className='img' />
							</div>
						</div>
					</div>
				
					<p>{events?.staticNewsTitles?.newsEventsDes}</p>
				</div>
			</div>
		</div>
  );
}

export default HeroNews;
