import React from 'react';

const FilterSideBar = ({ language, setTitleValue, setDateValue, setGenderValue }) => {
	return (
		<div className='rounded-[40px] h-auto bg-white'>
			<div className='p-4'>
				<p className='font-bold mb-4 text-xl text-[#59639c]'>{language === 'en' ? 'Filter By Date' : 'البحث بالتاريخ'}</p>
				<div className='flex items-center gap-3 mb-3'>
					<input value='saturday' onChange={(e) => setDateValue(e.target.value)} type='radio' name='date' id='sat' />
					<label htmlFor='sat'>{language === 'en' ? '	Saturday' : 'السبت'}</label>
				</div>
				<div className='flex items-center gap-3 mb-3'>
					<input value='sunday' onChange={(e) => setDateValue(e.target.value)} type='radio' name='date' id='sun' />
					<label htmlFor='sun'>{language === 'en' ? 'Sunday' : 'الأحد'}</label>
				</div>
				<div className='flex items-center gap-3 mb-3'>
					<input value='monday' onChange={(e) => setDateValue(e.target.value)} type='radio' name='date' id='mon' />
					<label htmlFor='mon'>{language === 'en' ? 'Monday' : 'الإثنين'}</label>
				</div>
				<div className='flex items-center gap-3 mb-3'>
					<input value='tuesday' onChange={(e) => setDateValue(e.target.value)} type='radio' name='date' id='tue' />
					<label htmlFor='tue'>{language === 'en' ? 'Tuesday' : 'الثلاثاء'}</label>
				</div>
				<div className='flex items-center gap-3 mb-3'>
					<input value='wednesday' onChange={(e) => setDateValue(e.target.value)} type='radio' name='date' id='wen' />
					<label htmlFor='wen'>{language === 'en' ? '	Wednesday' : 'الأربعاء'}</label>
				</div>
				<div className='flex items-center gap-3 mb-3'>
					<input value='thursday' onChange={(e) => setDateValue(e.target.value)} type='radio' name='date' id='thu' />
					<label htmlFor='thu'>{language === 'en' ? 'Thursday' : 'الخميس'}</label>
				</div>
				<div className='flex items-center gap-3 mb-3'>
					<input value='friday' onChange={(e) => setDateValue(e.target.value)} type='radio' name='date' id='fri' />
					<label htmlFor='fri'>{language === 'en' ? 'Friday' : 'الجمعة'}</label>
				</div>
				<p className='font-bold mb-4 text-xl text-[#59639c]'>{language === 'en' ? 'Filter By Title' : 'البحث بالتخصص'}</p>
				<div className='flex items-center gap-3 mb-3'>
					<input value='professor' onChange={(e) => setTitleValue(e.target.value)} type='radio' name='title' id='prof' />
					<label htmlFor='prof'>{language === 'en' ? 'Professor' : 'أستاذ'}</label>
				</div>
				<div className='flex items-center gap-3 mb-3'>
					<input value='consultant' onChange={(e) => setTitleValue(e.target.value)} type='radio' name='title' id='cons' />
					<label htmlFor='cons'>{language === 'en' ? '	Consultant' : 'استشاري'}</label>
				</div>
				<div className='flex items-center gap-3 mb-3'>
					<input value='specialist' onChange={(e) => setTitleValue(e.target.value)} type='radio' name='title' id='spec' />
					<label htmlFor='spec'>{language === 'en' ? 'Specialist' : 'اخصائي'}</label>
				</div>
				<p className='font-bold mb-4 text-xl text-[#59639c]'>{language === 'en' ? 'Filter By Gender' : 'البحث بالنوع'}</p>
				<div className='flex items-center gap-3 mb-3'>
					<input value='male' onChange={(e) => setGenderValue(e.target.value)} type='radio' name='gender' id='male' />
					<label htmlFor='male'>{language === 'en' ? 'Male' : 'ذكر'}</label>
				</div>
				<div className='flex items-center gap-3 mb-3'>
					<input value='female' onChange={(e) => setGenderValue(e.target.value)} type='radio' name='gender' id='female' />
					<label htmlFor='female'>{language === 'en' ? 'Female' : 'انثي'}</label>
				</div>
			</div>
		</div>
	);
};

export default FilterSideBar;
