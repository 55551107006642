import doctor from '../assets/doctors.jpg';
export const navTitle = [
	{
		title: 'HOME',
		titleArabic: 'الرئيسية',
		path: '/',
	},
	{
		title: 'ABOUT',
		titleArabic: 'من نحن',
		path: '/about',
	},
	{
		title: 'OUR SERVICES',
		titleArabic: 'خدماتنا',
		path: '/services',
	},
	{
		title: 'NEWS & MEDIA',
		titleArabic: 'الأخبار و الأحداث',
		path: '/news',
	},
	{
		title: 'OUR DOCTOR',
		titleArabic: 'أطبائنا',
		path: '/doctors',
	},
	// {
	//   title: "CONTACT US",
	//   titleArabic: "تواصل معنا",
	//   path: "#contact",
	// },
];
export const doctors = [
	{
		id: 1,
		img: doctor,
		title: 'Samah Abdel-Rahman El Bakry',
		desc: 'Professor of Immunology & Rehumatology Ain Shams University',
		btnText: 'Show More',
		content:
			'lLorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus est quidem id? Eligendi animi possimus molestias suscipit dignissimos commodi blanditiis atque delectus vel exercitationem incidunt veniam, quas iste est corporis?',
	},
	{
		id: 2,
		img: doctor,
		title: 'Samah Abdel-Rahman El Bakry',
		desc: 'Professor of Immunology & Rehumatology Ain Shams University',
		btnText: 'Show More',
		content:
			'lLorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus est quidem id? Eligendi animi possimus molestias suscipit dignissimos commodi blanditiis atque delectus vel exercitationem incidunt veniam, quas iste est corporis?',
	},
	{
		id: 3,
		img: doctor,
		title: 'Samah Abdel-Rahman El Bakry',
		desc: 'Professor of Immunology & Rehumatology Ain Shams University',
		btnText: 'Show More',
		content:
			'lLorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus est quidem id? Eligendi animi possimus molestias suscipit dignissimos commodi blanditiis atque delectus vel exercitationem incidunt veniam, quas iste est corporis?',
	},
	{
		id: 4,
		img: doctor,
		title: 'Samah Abdel-Rahman El Bakry',
		desc: 'Professor of Immunology & Rehumatology Ain Shams University',
		btnText: 'Show More',
		content:
			'lLorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus est quidem id? Eligendi animi possimus molestias suscipit dignissimos commodi blanditiis atque delectus vel exercitationem incidunt veniam, quas iste est corporis?',
	},
	{
		id: 5,
		img: doctor,
		title: 'Samah Abdel-Rahman El Bakry',
		desc: 'Professor of Immunology & Rehumatology Ain Shams University',
		btnText: 'Show More',
		content:
			'lLorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus est quidem id? Eligendi animi possimus molestias suscipit dignissimos commodi blanditiis atque delectus vel exercitationem incidunt veniam, quas iste est corporis?',
	},
	{
		id: 6,
		img: doctor,
		title: 'Samah Abdel-Rahman El Bakry',
		desc: 'Professor of Immunology & Rehumatology Ain Shams University',
		btnText: 'Show More',
		content:
			'lLorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus est quidem id? Eligendi animi possimus molestias suscipit dignissimos commodi blanditiis atque delectus vel exercitationem incidunt veniam, quas iste est corporis?',
	},
	{
		id: 7,
		img: doctor,
		title: 'Samah Abdel-Rahman El Bakry',
		desc: 'Professor of Immunology & Rehumatology Ain Shams University',
		btnText: 'Show More',
		content:
			'lLorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus est quidem id? Eligendi animi possimus molestias suscipit dignissimos commodi blanditiis atque delectus vel exercitationem incidunt veniam, quas iste est corporis?',
	},
	{
		id: 8,
		img: doctor,
		title: 'Samah Abdel-Rahman El Bakry',
		desc: 'Professor of Immunology & Rehumatology Ain Shams University',
		btnText: 'Show More',
		content:
			'lLorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus est quidem id? Eligendi animi possimus molestias suscipit dignissimos commodi blanditiis atque delectus vel exercitationem incidunt veniam, quas iste est corporis?',
	},
	{
		id: 9,
		img: doctor,
		title: 'Samah Abdel-Rahman El Bakry',
		desc: 'Professor of Immunology & Rehumatology Ain Shams University',
		btnText: 'Show More',
		content:
			'lLorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus est quidem id? Eligendi animi possimus molestias suscipit dignissimos commodi blanditiis atque delectus vel exercitationem incidunt veniam, quas iste est corporis?',
	},
	{
		id: 10,
		img: doctor,
		title: 'Samah Abdel-Rahman El Bakry',
		desc: 'Professor of Immunology & Rehumatology Ain Shams University',
		btnText: 'Show More',
		content:
			'lLorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus est quidem id? Eligendi animi possimus molestias suscipit dignissimos commodi blanditiis atque delectus vel exercitationem incidunt veniam, quas iste est corporis?',
	},
	{
		id: 11,
		img: doctor,
		title: 'Samah Abdel-Rahman El Bakry',
		desc: 'Professor of Immunology & Rehumatology Ain Shams University',
		btnText: 'Show More',
		content:
			'lLorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus est quidem id? Eligendi animi possimus molestias suscipit dignissimos commodi blanditiis atque delectus vel exercitationem incidunt veniam, quas iste est corporis?',
	},
	{
		id: 12,
		img: doctor,
		title: 'Samah Abdel-Rahman El Bakry',
		desc: 'Professor of Immunology & Rehumatology Ain Shams University',
		btnText: 'Show More',
		content:
			'lLorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus est quidem id? Eligendi animi possimus molestias suscipit dignissimos commodi blanditiis atque delectus vel exercitationem incidunt veniam, quas iste est corporis?',
	},
	{
		id: 13,
		img: doctor,
		title: 'Samah Abdel-Rahman El Bakry',
		desc: 'Professor of Immunology & Rehumatology Ain Shams University',
		btnText: 'Show More',
		content:
			'lLorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus est quidem id? Eligendi animi possimus molestias suscipit dignissimos commodi blanditiis atque delectus vel exercitationem incidunt veniam, quas iste est corporis?',
	},
	{
		id: 14,
		img: doctor,
		title: 'Samah Abdel-Rahman El Bakry',
		desc: 'Professor of Immunology & Rehumatology Ain Shams University',
		btnText: 'Show More',
		content:
			'lLorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus est quidem id? Eligendi animi possimus molestias suscipit dignissimos commodi blanditiis atque delectus vel exercitationem incidunt veniam, quas iste est corporis?',
	},
	{
		id: 15,
		img: doctor,
		title: 'Esraa AbdelMoneam',
		desc: 'Professor of Immunology & Rehumatology Ain Shams University',
		btnText: 'Show More',
		content:
			'lLorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus est quidem id? Eligendi animi possimus molestias suscipit dignissimos commodi blanditiis atque delectus vel exercitationem incidunt veniam, quas iste est corporis?',
	},
];
