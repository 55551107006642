import React, { useEffect, useState } from 'react';
import './Team.css';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import { Pagination, Navigation } from 'swiper';
import TeamCard from './TeamCard';
import { useWindowInner } from '../../hooks/useWindowInner';
import Spinner from '../Spinner/Spinner';
import right from '../../assets/Layer 1.svg';
import Left from '../../assets/Layer 2.svg';
const url_main = 'https://admin.elshoroukhospital.com';

function Team({ language }) {
	const { isMobile } = useWindowInner();
	const [loading, setLoading] = useState(true);
	const [teamData, setTeamData] = useState([]);
	console.log(teamData);
	useEffect(() => {
		fetch(`${url_main}/api/selectedTeam`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				lang: language,
			},
		})
			.then((res) => {
				return res.json();
			})
			.then((data) => {
				setTeamData(data.data);
				setLoading(false);
			});
	}, [language]);
	return (
		<>
			{loading ? (
				<Spinner />
			) : (
				<div id='team' className='team container'>
					<div className='flex justify-center items-center mb-12'>
						<div className='flex items-center gap-6'>
							<div className='iconContainer'>
								<img src={right} alt='' className='img' />
							</div>

							<h2 className='title'>{language === 'en' ? 'Our Team' : 'اطبائنا'}</h2>
							<div className='iconContainer'>
								<img src={Left} alt='' className='img' />
							</div>
						</div>
					</div>

					<div className='team__swiper relative px-4'>
						<Swiper
							slidesPerView={isMobile ? 1 : 4}
							spaceBetween={60}
							slidesPerGroup={1}
							loop={true}
							loopFillGroupWithBlank={true}
							navigation={true}
							pagination={{
								clickable: true,
							}}
							modules={[Pagination, Navigation]}
							className='mySwiper'
						>
							{teamData.map((item, index) => (
								<SwiperSlide key={index}>
									<TeamCard item={item} language={language} />
								</SwiperSlide>
							))}
						</Swiper>
					</div>
				</div>
			)}
		</>
	);
}

export default Team;

/*
  	
*/
