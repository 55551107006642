import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { BsGlobe2 } from 'react-icons/bs';
import './Navbar.css';
import lang from '../../assets/lang.svg';
import { navTitle } from '../../utils/data';
import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai';
import { IoMdArrowDropdown, IoMdArrowDropup } from 'react-icons/io';
import Spinner from '../Spinner/Spinner';

const Navbar = ({ setLanguage, language, logo, siteName, loadingLogo }) => {
	const [click, setClick] = useState(false);
	const [dropLang, setDropLang] = useState(false);

	const handleClick = () => setClick(!click);

	return (
		<>
			<nav className='navbar'>
				<div className='navbar-container'>
					{loadingLogo ? (
						<Spinner />
					) : (
						<Link to='/' className='navbar-logo'>
							<img src={logo} alt='logo' />
							<div>
								<h4>{siteName?.siteTitle_en}</h4>
								<h3 className='text-xl'>{siteName?.siteTitle_ar}</h3>
							</div>
						</Link>
					)}

					<div className='menu-icon' onClick={handleClick}>
						<span>{click ? <AiOutlineClose /> : <AiOutlineMenu />}</span>
					</div>

					<ul className={click ? 'nav-menu active ' : 'nav-menu'}>
						{navTitle.map((item, index) => (
							<li className='nav-item' key={index}>
								<NavLink
									to={item.path}
									className={'nav-links '}
									onClick={() => {
										handleClick();
									}}
								>
									{language === 'en' ? item.title : item.titleArabic}
								</NavLink>
							</li>
						))}
						<li>
							<a
								href='#contact'
								className={'nav-links '}
								onClick={() => {
									handleClick();
								}}
							>
								{language === 'en' ? 'CONTACT US' : 'تواصل معنا'}
							</a>
						</li>

						{/* New Select */}
						<div className='flex items-center gap-1 relative navbar__lang'>
							<img src={lang} alt='lang/img' />

							<div className='relative  cursor-pointer' onClick={() => setDropLang(!dropLang)}>
								<h4 className=''> {language === 'en' ? 'EN' : 'عربي'}</h4>
								<span className='flex-center absolute langs-arrows'>
									{dropLang ? (
										<span className='flex-center'>
											<IoMdArrowDropup />
										</span>
									) : (
										<span className='flex-center'>
											<IoMdArrowDropdown />
										</span>
									)}
								</span>
							</div>
							{dropLang && (
								<ul className='dropDown-langs-options'>
									<li
										className='cursor-pointer optionLang'
										onClick={() => {
											setDropLang(false);
											setLanguage('en');
											handleClick();
											localStorage.setItem('shroukLanguage', JSON.stringify('en'));
										}}
									>
										EN
									</li>
									<li
										className='cursor-pointer optionLang '
										onClick={() => {
											setDropLang(false);
											setLanguage('ar');
											handleClick();
											localStorage.setItem('shroukLanguage', JSON.stringify('ar'));
										}}
									>
										عربي
									</li>
								</ul>
							)}
						</div>
					</ul>
				</div>
			</nav>
		</>
	);
};

export default Navbar;
