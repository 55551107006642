import React from 'react';
import { Link } from 'react-router-dom';
import style from './RelatedDoctors.module.css';
import Left from '../../../assets/Layer 2.svg';
import right from '../../../assets/Layer 1.svg';
const RelatedDoctors = ({ language, relatedDoctors }) => {
	console.log(relatedDoctors);

	return (
		<div>
			<div className='flex justify-center items-center mb-12'>
				<div className='flex items-center gap-6'>
					<div className={style.iconContainer}>
						<img src={right} alt='' className={style.img} />
					</div>

					<h2 className={style.title}>{language === 'en' ? 'Related Doctors' : 'الأطباء ذوي الصلة'}</h2>
					<div className={style.iconContainer}>
						<img src={Left} alt='' className={style.img} />
					</div>
				</div>
			</div>
			<div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8'>
				{relatedDoctors.map((item, index) => (
					<div key={index} className='p-1  shadow-lg'>
						<div className='flex  gap-8'>
							<div className='w-1/3'>
								<img src={item.image} alt='' className='w-full h-full object-cover' />
							</div>
							<div className='w-2/3'>
								<Link to={`/doctors/${item.id}`} className='text-[#193569] text-2xl mb-3 font-bold'>
									{item.name}
								</Link>
								<p className=' text-lg text-[#193569] my-4'>{item.job_title}</p>
								<p className='my-4' dangerouslySetInnerHTML={{ __html: item.description }}></p>
							</div>
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default RelatedDoctors;
